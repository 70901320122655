<template>
 <div>
        <Header />
        <br /><br /><br /><br /><br /><br /><br />
        <div class="container fluid">
          <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/auxiliary_equipement/adhesif_and_liquid_dispenser">
          <div class="card" style="width:260px;height:180px;">
          
            <img
              style="max-height:70%; max-width:60%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/auxiliary/adysif.jpg"
              alt="Card image cap"
            />
            
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Adhesif and liquid dispenser 
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/auxiliary_equipement/electriquescrew">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:100%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/auxiliary/screwelct.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Electric Torque Screwdrivers
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
      
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/auxiliary_equipement/pneumatique_screw_device">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:100%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/auxiliary/screw.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Pneumatic Screw Devices
              </h4>
            </div>
          </div>
          </router-link>
        </div>
          <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/commingsoon">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/auxiliary/pipe.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
              Modular System Equipement
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/auxiliary_equipement/perform_machine">
          <div class="card" style="width:260px;height:180px;">
          
            <img
              style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/auxiliary/perform.webp"
              alt="Card image cap"
            />
            
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
               Preform Machine 
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="solderingmixingmachines()">
          <div class="card" style="width:260px;height:180px;">
          
            <img
              style="max-height:80%; max-width:60%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/auxiliary/soldering_mixing.jpg"
              alt="Card image cap"
            />
            
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Soldering Mixing Machines 
              </h4>
            </div>
          </div>
          </a>
        </div>
        <!-- end col -->
        </div>
      </div>
        <Footer />
    </div>
</template>
<script>
import Header from "../../components/Header.vue";

import Footer from "../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>